import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="Informativa sul trattamento dei dati personali" />
    <div className="a-container p-privacy">
      <h1>
        Informativa sul trattamento dei dati personali ex artt. 13-14 Reg.to UE
        2016/679
      </h1>
      <p>
        FARMAMED S.r.l. Unipersonale (di seguito il Titolare) nella qualità di
        Titolare del trattamento dei Suoi dati personali, ai sensi e per gli
        effetti del Reg.to UE 2016/679 di seguito 'GDPR', con la presente La
        informa che la citata normativa prevede la tutela degli interessati
        rispetto al trattamento dei dati personali e che tale trattamento sarà
        improntato ai principi di correttezza, liceità, trasparenza e di tutela
        della Sua riservatezza e dei Suoi diritti.
        <h2>Finalità di trattamento e base giuridica del trattamento:</h2>
        <p>
          Previo il suo consenso, i Suoi dati personali saranno utilizzati per
          dare seguito alla sua richiesta di contatto o di informazioni. Il
          conferimento dei dati è per Lei necessario per rendere possibile la
          nostra risposta alla richiesta di contatto e/o di informazioni.
        </p>
        <h2>Modalità del trattamento</h2>
        <p>
          I suoi dati personali potranno essere trattati nei seguenti modi:
          <ul>
            <li>trattamento a mezzo di sistemi informatici;</li>
            <li>trattamento manuale a mezzo di archivi cartacei.</li>
          </ul>
          Ogni trattamento avviene nel rispetto delle modalità di cui agli artt.
          6, 32 del GDPR e mediante l'adozione delle adeguate misure di
          sicurezza previste.
        </p>
        <h2>Comunicazione</h2>
        <p>
          I suoi dati saranno trattati unicamente da personale espressamente
          autorizzato dal Titolare ai sensi dell’Art. 29 del GDPR I suoi dati
          potranno essere comunicati a terzi debitamente nominati Responsabili
          al trattamento, in particolare a soggetti esterni che si occupano
          dello sviluppo, assistenza e manutenzione dei data base del sito web e
          che forniscono servizi di hosting. I suoi dati personali non verranno
          diffusi in alcun modo.
        </p>
        <h2>Periodo di conservazione dei dati</h2>
        <p>
          I suoi dati saranno conservati per il tempo necessario ad evadere la
          sua richiesta di contatto o informazioni.
        </p>
        <h2>Trasferimento dei dati in Paesi extra UE</h2>
        <p>I suoi dati personali saranno conservati in Unione Europea.</p>
        <h2>Titolare del trattamento dei dati</h2>
        <p>
          Titolare del trattamento è FARMAMED S.R.L. UNIPERSONALE con sede
          legale in Via Cisa, 2 – 46034 Borgo Virgilio (MN) - mail:
          info@farmamed.it
        </p>
        <h2>I Suoi diritti</h2>
        <p>
          Lei ha diritto di ottenere dal titolare la cancellazione (diritto
          all'oblio), la limitazione, l'aggiornamento, la rettificazione, la
          portabilità, l'opposizione al trattamento dei dati personali che La
          riguardano, nonché in generale può esercitare tutti i diritti previsti
          dagli artt. 15, 16, 17, 18, 19, 20, 21, 22 del GDPR. Per l'esercizio
          dei Suoi diritti, puoi inviare una comunicazione all’indirizzo sotto
          indicato ovvero un e-mail a info@farmamed.it Può presentare una
          segnalazione o un reclamo rivolgendosi all’Autorità Garante della
          Privacy con sede in Piazza Venezia, 11 - 00186 ROMA - mail:
          urp@gpdp.it
        </p>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
